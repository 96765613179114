import { Switch } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import {
  Button,
  useCreate,
  useGetList,
  useNotify,
  useUpdate,
} from "react-admin";
import TextField from "@mui/material/TextField";

type OpenCloseStore = {
  id: string;
  isOpen: boolean;
  openClosedMessage: string;
} | null;

const resourse = "store-settings";

function CloseOpenStores() {
  const [openCloseStores, setOpenCloseStores] =
    React.useState<OpenCloseStore>(null);
  const { data, isLoading } = useGetList(resourse);
  const [update] = useUpdate();
  const [create] = useCreate();
  const notify = useNotify();

  React.useEffect(() => {
    const start = async () => {
      if (data && data[0]) setOpenCloseStores(data[0]);
      else if (data && !data[0]) {
        try {
          const clos = await create(resourse, {
            data: {
              isOpen: true,
              openClosedMessage: "",
            },
          });
          setOpenCloseStores(clos[0]);
        } catch {
          setOpenCloseStores(null);
        }
      } else setOpenCloseStores(null);
    };

    start();
  }, [data]);

  const onChange = async (_: any, checked: boolean) => {
    const normalizedData = {
      id: openCloseStores!.id,
      isOpen: !checked,
      openClosedMessage: openCloseStores!.openClosedMessage,
    };
    setOpenCloseStores(normalizedData);
  };

  const onSave = async () => {
    await update(resourse, {
      data: {
        isOpen: openCloseStores!.isOpen,
        openClosedMessage: openCloseStores!.openClosedMessage,
      },
      id: openCloseStores!.id,
      previousData: data![0],
    });

    notify(`Saved`, { type: "success" });
  };

  if (!isLoading)
    return (
      <div>
        <div style={{ margin: "16px 0" }}>
          Current App Status: {openCloseStores?.isOpen ? "Default" : "Closed"}
        </div>
        <div>
          <Switch
            checked={!openCloseStores?.isOpen || false}
            onChange={onChange}
          />
        </div>
        <TextField
          value={openCloseStores?.openClosedMessage || ""}
          label={"Message"}
          onChange={(e) =>
            //@ts-ignore
            setOpenCloseStores((p) => ({
              ...p,
              openClosedMessage: e.target.value,
            }))
          }
        />
        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "row",
            gap: 16,
          }}
        >
          <Button className={"i-need-fix-mui-btn"} onClick={onSave}>
            <span>Save</span>
          </Button>
        </div>
      </div>
    );
  return <div>"Loading..."</div>;
}

export default CloseOpenStores;
