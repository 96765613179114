import {
  Autocomplete,
  Box,
  MenuItem,
  Select,
  TextField as MUITextField,
} from "@mui/material";
import React, { useState } from "react";
import {
  Edit,
  ImageField,
  ImageInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useEditContext,
} from "react-admin";
import config from "../config";
import { useFormContext } from "react-hook-form";
import RedirectUrlEditor from "./RedirectUrlEditor";

const isActive: boolean[] = [true, false];
const isDefault: boolean[] = [true, false];
const toChoices = (items: boolean[] | string[]) =>
  items.map((item) => ({ id: item, name: item }));

const transform = (data: any, { previousData }: { previousData: any }) => {
  console.log({ data, previousData });
  return {
    ...data,
    image: data?.image === previousData?.image ? undefined : data.image,
  };
};

export default function ShowSingleBannerItems(props: any) {
  return (
    <Edit {...props} transform={transform}>
      <SimpleShowLayout>
        <TextField label="Id" source="id" />
      </SimpleShowLayout>
      <SimpleForm noValidate>
        <TextInput label="Position" source="position" />
        <ImageField source="imageUrl" title="title" />
        <TextInput label={"Name"} source="name" />

        <RedirectUrlEditor />
        <ImageInput
          source="image"
          label="Related pictures"
          accept="image/png, image/jpeg"
        >
          <ImageField source="src" title="image" />
        </ImageInput>
        <SelectInput
          source="isDefault"
          choices={toChoices(isDefault)}
          validate={[required()]}
        />
        <SelectInput
          source="isActive"
          choices={toChoices(isActive)}
          validate={[required()]}
        />
        <TextInput label={"bannerId"} source="bannerId" />
      </SimpleForm>
    </Edit>
  );
}
