import {
  Edit,
  required,
  SelectInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";

const isActive: any[] = [true, false];
const type: string[] = ["static", "slider"];
const toChoices = (items: string[]) => items.map((item: string) => ({ id: item, name: item }));

export default function ShowBannerItem(props: any) {
  return (
    <Edit {...props}>
      <SimpleShowLayout>
        <TextField label="Id" source="id" />
      </SimpleShowLayout>
      <SimpleForm>
        <TextInput label="Name" source="name" />
        <SelectInput source="type" choices={toChoices(type)} validate={[required()]} />
        <SelectInput source="isActive" choices={toChoices(isActive)} validate={[required()]} />
      </SimpleForm>
    </Edit>
  );
}
