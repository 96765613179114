import { Button, Modal } from "@mui/material";
import React, { useState } from "react";
import { AppBar, useNotify } from "react-admin";
import config from "../config";

function CustomAppBar() {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const notify = useNotify();

  const syncDb = async () => {
    const tokenId = localStorage.getItem("token");
    fetch(`${config.API_URL}/admin/commands/import`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        notify(`DB synced`, { type: "success" });

        closeModal();
      })
      .catch(() => {
        notify(`Failed to sync DB`, { type: "error" });
      });
  };
  return (
    <AppBar>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          height: 36,
        }}
      >
        <Button onClick={openModal} style={{ color: "white", width: 86 }}>
          SYNC DB
        </Button>
        <Modal open={isOpen} onClose={closeModal}>
          <div
            style={{
              width: 450,
              height: 150,
              borderRadius: 16,

              background: "white",
              margin: "auto",
              marginTop: 200,
              display: "flex",
              padding: 24,
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "space-between",
              boxSizing: "border-box",
            }}
          >
            <div>
              Do you want to synchronize the database and DEAR inventory system?
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Button onClick={closeModal} color="error">
                No
              </Button>
              <Button onClick={syncDb}>Yes</Button>
            </div>
          </div>
        </Modal>
      </div>
    </AppBar>
  );
}

export default CustomAppBar;
