import { Button } from "react-admin";
import getInvoice from "../utils/getInvoice";

export default function DownloadInvoice({
  RecordId,
  orderNumber,
  isList = false,
}: any) {
  const onGetInvoice = (e: any) => {
    e.stopPropagation();
    getInvoice(RecordId, orderNumber);
  };

  return (
    <Button
      sx={isList ? {} : { background: "#2196f370" }}
      onClick={onGetInvoice}
      label="Print Slip"
    />
  );
}
