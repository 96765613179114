import { Admin, Layout, Resource } from "react-admin";
import authProvider from "../components/authProvider";
import {
  OrdersList,
  BannersList,
  BannersItemsList,
  // CategoriesList
} from "../components/ItemsList";
import customDataProvider from "../components/fetchApiData";
import ShowOrderItem from "../components/showOrderItem";
import ShowBannerItem from "../components/showBannerItem";
import CreateBanner from "../components/createBanner";
import CreateBannerItems from "../components/createBannerItems";
import ShowSingleBannerItems from "../components/showSingleBannerItems";
import ClosingHoursShow from "../components/closingHoursShow";
import CustomAppBar from "../components/CustomAppBar";
import CloseOpenStores from "../components/closeOpenStores";

const MyLayout = (props: any) => <Layout {...props} appBar={CustomAppBar} />;

const App = () => (
  <Admin
    authProvider={authProvider}
    dataProvider={customDataProvider}
    layout={MyLayout}
  >
    <Resource name="orders" list={OrdersList} edit={ShowOrderItem} />
    <Resource
      name="banners"
      list={BannersList}
      edit={ShowBannerItem}
      create={CreateBanner}
    />
    <Resource
      name="banner-items"
      list={BannersItemsList}
      edit={ShowSingleBannerItems}
      create={CreateBannerItems}
    />
    <Resource name="store-settings" list={CloseOpenStores} />
  </Admin>
);

export default App;
