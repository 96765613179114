import React from "react";
import {
  Button,
  useGetRecordId,
  useRecordContext,
  useRefresh,
  useUpdate,
} from "react-admin";
import config from "../config";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import getParcels from "../utils/getParcels";
import DownloadInvoice from "./downloadInvoice";

function ItemButtons() {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [parcelsQuantity, setParcelsQuantity] = React.useState("1");
  const order = useRecordContext();

  const refresh = useRefresh();

  const [changeStatusToPacked] = useUpdate("orders", {
    data: { status: "packed" },
    id: order.id,
    previousData: order,
  });

  const changeToPlaced = (e: any) => {
    e.stopPropagation();
    changeStatusToPacked();
  };

  const changeParcels = (e: any) => {
    e.stopPropagation();
    setIsModalOpen(false);
    const tokenId = localStorage.getItem("token");
    setIsLoading(true);
    fetch(`${config.API_URL}/admin/parcels/create-many`, {
      method: "POST",
      body: JSON.stringify({
        orderId: order.id,
        quantity: Number(parcelsQuantity),
      }),
      headers: {
        Authorization: `Bearer ${tokenId}`,
        "Content-Type": "application/json",
      },
    }).then(() => {
      // getParcels(order.id, order.number);
      refresh();
    });
  };

  const openModal = (e: any) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const closeModal = (e: any) => {
    e.stopPropagation();
    setIsModalOpen(false);
  };

  return (
    <>
      {order?.parcelsCount > 0 && order?.status === "placed" && (
        <Button onClick={changeToPlaced} label="Packed" />
      )}
      {order?.parcelsCount === 0 && (
        <Button onClick={openModal} label="Accept" />
      )}
      <Modal open={isModalOpen} onClose={closeModal}>
        <div className="modal" onClick={closeModal}>
          <div className="modal__inner" onClick={(e) => e.stopPropagation()}>
            <h3 className="modal__header">How many parcels?</h3>
            <TextField
              onKeyUp={(event) => {
                if (!/[0-9]/.test(event.key)) event.preventDefault();
              }}
              onChange={(e) => {
                const pq = Number(e.target.value);
                setParcelsQuantity(e.target.value);
              }}
              value={parcelsQuantity}
              type={"number"}
            />
            {Number(parcelsQuantity) > 20 && (
              <span className="error-text">
                You are not allowed to have more than 20 parcels
              </span>
            )}
            {Number(parcelsQuantity) <= 0 && (
              <span className="error-text">
                You are not allowed to have less than 1 parcels
              </span>
            )}
            <Button
              onClick={changeParcels}
              label={isLoading ? "Loading..." : "Save"}
              disabled={
                isLoading ||
                Number(parcelsQuantity) > 20 ||
                Number(parcelsQuantity) <= 0
              }
              style={{ width: 120, marginLeft: 180 }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ItemButtons;

export const OrderItemButtonsSecond = () => {
  const order = useRecordContext();
  const RecordId = useGetRecordId();

  const onGetParcels = (e: any) => {
    e.stopPropagation();
    getParcels(order.id, order.number);
  };

  return (
    <div>
      <DownloadInvoice RecordId={RecordId} orderNumber={order.number} isList />
      {order?.parcelsCount > 0 && (
        <Button onClick={onGetParcels} label="Label" />
      )}
    </div>
  );
};
