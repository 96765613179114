import {
  Create,
  ImageField,
  ImageInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import RedirectUrlEditor from "./RedirectUrlEditor";

const isDefault: any[] = [true, false];
const isActive: any[] = [true, false];
const toChoices = (items: string[]) =>
  items.map((item: string) => ({ id: item, name: item }));

export default function CreateBannerItems() {
  return (
    <Create>
      <SimpleForm>
        <TextInput
          validate={[required()]}
          label={"bannerId"}
          source="bannerId"
        />
        <TextInput validate={[required()]} label={"name"} source="name" />
        <RedirectUrlEditor />
        <SelectInput
          source="isDefault"
          choices={toChoices(isDefault)}
          validate={[required()]}
        />
        <SelectInput
          source="isActive"
          choices={toChoices(isActive)}
          validate={[required()]}
        />
        <ImageInput source="image" label="Related Pictures">
          <ImageField source="src" title="image" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
}
