import config from "../config";

const getParcels = (orderId: string | number, orderNumber: string) => {
  const tokenId = localStorage.getItem("token");
  fetch(`${config.API_URL}/admin/orders/${orderId}/parcel-labels`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenId}`,
      Accept: "application/octet-stream",
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${orderNumber} Labels.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    });
};

export default getParcels;
