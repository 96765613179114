import { Create, required, SelectInput, SimpleForm, TextInput } from "react-admin";

const isActive: any[] = [true, false];
const type: string[] = ["static", "slider"];
const toChoices = (items: string[]) => items.map((item: string) => ({ id: item, name: item }));

export default function CreateBanner(props: any) {
  return (
    <Create>
        <SimpleForm>
          <TextInput validate={[required()]} label={"Name"} source="name" />
          <SelectInput validate={[required()]} source="type" choices={toChoices(type)} />
          <SelectInput validate={[required()]} source="isActive" choices={toChoices(isActive)} />
        </SimpleForm>
    </Create>
  )
}
