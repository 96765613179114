/* eslint-disable import/no-anonymous-default-export */
import { AUTH_CHECK, AUTH_ERROR, AUTH_LOGIN, AUTH_LOGOUT } from "react-admin";
import jwt_decode from "jwt-decode";
import config from "../config";

const authURL = `${config.API_URL}/auth`;

interface IParams {
  username: string;
  password: string;
  status: any;
}

export default (type: string, params: IParams) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(`${authURL}/sing-in`, {
      method: "POST",
      body: JSON.stringify({
        email: username,
        password: password,
      }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ idToken, refreshToken }) => {
        localStorage.setItem("token", idToken);
        localStorage.setItem("refreshToken", refreshToken);
      });
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    const status = params?.status;
    var token: any = localStorage.getItem("token");
    var refreshToken: any = localStorage.getItem("refreshToken");
    var decoded: any = jwt_decode(token);
    const request = new Request(`${authURL}/refresh-token`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-refresh-token": refreshToken,
      },
      body: JSON.stringify({ id: decoded.name }),
    });
    if (status === 401) {
      return fetch(request)
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then(({ idToken }) => {
          localStorage.setItem("token", idToken);
          return Promise.resolve();
        });
    }
    if (status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      return Promise.reject();
    }
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return localStorage.getItem("token")
      ? Promise.resolve()
      : Promise.reject({ redirectTo: "/login" });
  }
  return Promise.resolve();
};
