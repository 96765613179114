import {
  Autocomplete,
  Box,
  MenuItem,
  Select,
  TextField as MUITextField,
} from "@mui/material";
import React, { useState } from "react";
import {
  Edit,
  ImageField,
  ImageInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useEditContext,
} from "react-admin";
import config from "../config";
import { useFormContext } from "react-hook-form";

const isRedirectUrlType: string[] = ["custom", "brand", "category", "product"];
const toChoices = (items: boolean[] | string[]) =>
  items.map((item) => ({ id: item, name: item }));

const RedirectUrlEditor = () => {
  const x = useEditContext();
  const form = useFormContext();
  const [type, setType] = useState(isRedirectUrlType[3]);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [options, setOptions] = useState<any[]>([]);

  const onTypeChange = (e: any) => {
    setType(e.target.value);
  };

  const onOptionChange = (_: any, value: any) => {
    setSelectedOption(value);
    form.setValue(
      "redirectUrl",
      `https://www.deeliver.co.za/${type}/${value.id}`,
      {
        shouldTouch: true,
        shouldValidate: true,
        shouldDirty: true,
      }
    );
  };

  const fetchOptions = () => {
    if (options.length === 0) {
      const tokenId = localStorage.getItem("token");
      fetch(getTypeUrl(type), {
        method: "GET",
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          setOptions(json.data);
        });
    }
  };

  React.useEffect(() => {
    if (x.record?.redirectUrl) {
      const selectedOptionId = x.record.redirectUrl.split("/").reverse()[0];
      const urlType = getTypeFromUrl(x.record.redirectUrl);
      setType(urlType);

      if (selectedOptionId) {
        const tokenId = localStorage.getItem("token");
        fetch(`${getTypeUrl(urlType)}/${selectedOptionId}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        })
          .then((response) => response.json())
          .then((json) => {
            setSelectedOption(json);
          });
      }
    }
  }, [x.record?.redirectUrl]);

  React.useEffect(() => {
    setOptions([]);
    setSelectedOption("");
  }, [type]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 32,
        marginBottom: 16,
      }}
    >
      <Select sx={{ width: 160 }} value={type} onChange={onTypeChange}>
        {toChoices(isRedirectUrlType).map((val) => (
          // @ts-ignore
          <MenuItem value={val.id}>{capitalize(val.name)}</MenuItem>
        ))}
      </Select>
      {type !== isRedirectUrlType[0] ? (
        <Autocomplete
          sx={{ width: 260 }}
          key={selectedOption}
          options={options}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              {option.name}
            </Box>
          )}
          onChange={onOptionChange}
          value={selectedOption || undefined}
          renderInput={(params) => (
            <MUITextField
              {...params}
              label={`Choose a ${type}`}
              onClick={fetchOptions}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
      ) : (
        <TextInput label={"Redirect Url"} source="redirectUrl" />
      )}
    </div>
  );
};

const getTypeUrl = (type: string) => {
  let url = "products";
  if (type === isRedirectUrlType[1]) url = "brands";
  if (type === isRedirectUrlType[2]) url = "categories";
  return `${config.API_URL}/${url}`;
};

const getTypeFromUrl = (url: string) => {
  if (url.includes("brand")) return isRedirectUrlType[1];
  if (url.includes("category")) return isRedirectUrlType[2];
  if (url.includes("product")) return isRedirectUrlType[3];
  return isRedirectUrlType[0];
};

function capitalize(s: string) {
  return s[0].toUpperCase() + s.slice(1);
}

export default RedirectUrlEditor;
