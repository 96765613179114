import config from "../config";

const getInvoice = (RecordId: string | number, orderNumber: string) => {
  const tokenId = localStorage.getItem("token");
  fetch(`${config.API_URL}/admin/orders/${RecordId}/packing-slip`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenId}`,
      // 'Content-Type': 'application/pdf',
      Accept: "application/octet-stream",
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${orderNumber} Packing Slip.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    });
};

export default getInvoice;
