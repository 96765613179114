import {
  Datagrid,
  List,
  TextField,
  Pagination,
  SearchInput,
  SelectInput,
  TopToolbar,
  FilterButton,
  CreateButton,
  ListContextProvider,
  useList,
} from "react-admin";
import ItemButtons, { OrderItemButtonsSecond } from "./ItemButtons";

const PostPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
);
const orderFilters = [
  <SearchInput source="number" alwaysOn />,
  <SelectInput
    source="status"
    alwaysOn
    choices={[
      { id: "placed", name: "placed" },
      { id: "packed", name: "packed" },
      { id: "dispatched", name: "dispatched" },
      { id: "tracking", name: "tracking" },
      { id: "arrived", name: "arrived" },
      { id: "delivered", name: "delivered" },
    ]}
  />,
];

const OrdersListActions = () => (
  <TopToolbar>
    <FilterButton />
  </TopToolbar>
);

const DefaultActions = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

export function OrdersList(props: any) {
  const listContext = useList({ data: props?.data, isLoading: false });
  return (
    <ListContextProvider value={listContext}>
      <List
        {...props}
        filters={orderFilters}
        actions={<OrdersListActions />}
        pagination={<PostPagination />}
      >
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <TextField label="Order number" source="number" sortable={false} />
          <ItemButtons />
          <TextField source="status" sortable={false} />
          <TextField
            label="User Name"
            source="user.firstName"
            sortable={false}
          />
          <OrderItemButtonsSecond />
        </Datagrid>
      </List>
    </ListContextProvider>
  );
}

export function BannersList(props: any) {
  return (
    <List
      {...props}
      pagination={<PostPagination />}
      actions={<DefaultActions />}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="id" sortable={false} />
        <TextField source="name" sortable={false} />
        <TextField source="type" sortable={false} />
        <TextField label="Active" source="isActive" sortable={false} />
      </Datagrid>
    </List>
  );
}

export function BannersItemsList(props: any) {
  return (
    <List
      {...props}
      pagination={<PostPagination />}
      actions={<DefaultActions />}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="id" sortable={false} />
        <TextField source="bannerId" sortable={false} />
        <TextField source="name" sortable={false} />
        <TextField source="isDefault" sortable={false} />
        <TextField label="Active" source="isActive" sortable={false} />
      </Datagrid>
    </List>
  );
}

export function CategoriesList(props: any) {
  return (
    <List {...props} pagination={<PostPagination />} actions={false}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="id" sortable={false} />
        <TextField source="name" sortable={false} />
        <TextField source="image" sortable={false} />
      </Datagrid>
    </List>
  );
}
